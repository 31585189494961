define("discourse/plugins/discourse-custom-wizard/discourse/components/custom-wizard-field", ["exports", "@ember/component", "@ember/string", "discourse-common/utils/decorators", "discourse/lib/text"], function (_exports, _component, _string, _decorators, _text) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _obj, _init, _init2;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer && (Object.defineProperty(i, e, a), a = null), a; }
  var _default = _exports.default = _component.default.extend((_dec = (0, _decorators.default)("field.type", "field.id"), _dec2 = (0, _decorators.default)("field.id"), _dec3 = (0, _decorators.default)("field.type", "field.id"), _dec4 = (0, _decorators.default)("field.type"), (_obj = {
    classNameBindings: [":wizard-field", "typeClasses", "field.invalid", "field.id"],
    didReceiveAttrs() {
      this._super(...arguments);
      (0, _text.cook)(this.field.translatedDescription).then(cookedDescription => {
        this.set("cookedDescription", cookedDescription);
      });
    },
    typeClasses: (type, id) => `${(0, _string.dasherize)(type)}-field ${(0, _string.dasherize)(type)}-${(0, _string.dasherize)(id)}`,
    fieldClass: id => `field-${(0, _string.dasherize)(id)} wizard-focusable`,
    inputComponentName(type, id) {
      if (["text_only"].includes(type)) {
        return false;
      }
      return (0, _string.dasherize)(type === "component" ? id : `custom-wizard-field-${type}`);
    },
    textType(fieldType) {
      return ["text", "textarea"].includes(fieldType);
    }
  }, (_applyDecoratedDescriptor(_obj, "typeClasses", [_dec], (_init = Object.getOwnPropertyDescriptor(_obj, "typeClasses"), _init = _init ? _init.value : undefined, {
    enumerable: true,
    configurable: true,
    writable: true,
    initializer: function () {
      return _init;
    }
  }), _obj), _applyDecoratedDescriptor(_obj, "fieldClass", [_dec2], (_init2 = Object.getOwnPropertyDescriptor(_obj, "fieldClass"), _init2 = _init2 ? _init2.value : undefined, {
    enumerable: true,
    configurable: true,
    writable: true,
    initializer: function () {
      return _init2;
    }
  }), _obj), _applyDecoratedDescriptor(_obj, "inputComponentName", [_dec3], Object.getOwnPropertyDescriptor(_obj, "inputComponentName"), _obj), _applyDecoratedDescriptor(_obj, "textType", [_dec4], Object.getOwnPropertyDescriptor(_obj, "textType"), _obj)), _obj)));
});