define("discourse/plugins/discourse-custom-wizard/discourse/controllers/custom-wizard-index", ["exports", "@ember/controller", "@ember/object/computed", "discourse-common/utils/decorators"], function (_exports, _controller, _computed, _decorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _obj;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer && (Object.defineProperty(i, e, a), a = null), a; }
  const reasons = {
    noWizard: "none",
    requiresLogin: "requires_login",
    notPermitted: "not_permitted",
    completed: "completed"
  };
  var _default = _exports.default = _controller.default.extend((_dec = (0, _decorators.default)("noAccessReason"), (_obj = {
    noAccess: (0, _computed.or)("noWizard", "requiresLogin", "notPermitted", "completed"),
    noAccessI18nKey(reason) {
      return reason ? `wizard.${reasons[reason]}` : "wizard.none";
    },
    noAccessReason() {
      return Object.keys(reasons).find(reason => this.get(reason));
    }
  }, (_applyDecoratedDescriptor(_obj, "noAccessI18nKey", [_dec], Object.getOwnPropertyDescriptor(_obj, "noAccessI18nKey"), _obj), _applyDecoratedDescriptor(_obj, "noAccessReason", [_decorators.default], Object.getOwnPropertyDescriptor(_obj, "noAccessReason"), _obj)), _obj)));
});