define("discourse/plugins/discourse-custom-wizard/discourse/models/custom-wizard-admin", ["exports", "@ember/object", "discourse/plugins/discourse-custom-wizard/discourse/lib/wizard-json", "discourse/plugins/discourse-custom-wizard/discourse/lib/wizard", "discourse/plugins/discourse-custom-wizard/discourse/lib/wizard-schema", "rsvp", "discourse/lib/ajax", "discourse/lib/ajax-error", "discourse-common/utils/decorators"], function (_exports, _object, _wizardJson, _wizard, _wizardSchema, _rsvp, _ajax, _ajaxError, _decorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _obj;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer && (Object.defineProperty(i, e, a), a = null), a; }
  const GUEST_GROUP_ID = -1;
  const CustomWizardAdmin = _object.default.extend((_dec = (0, _decorators.default)("permitted.@each.output"), (_obj = {
    allowGuests(permitted) {
      return permitted && permitted.filter(p => p.output && p.output.includes(GUEST_GROUP_ID)).length;
    },
    save(opts) {
      return new _rsvp.Promise((resolve, reject) => {
        let wizard = this.buildJson(this, "wizard");
        if (wizard.error) {
          reject(wizard);
        }
        let data = {
          wizard
        };
        if (opts.create) {
          data.create = true;
        }
        (0, _ajax.ajax)(`/admin/wizards/wizard/${wizard.id}`, {
          type: "PUT",
          contentType: "application/json",
          data: JSON.stringify(data)
        }).then(result => {
          if (result.backend_validation_error) {
            reject(result);
          } else {
            resolve(result);
          }
        });
      });
    },
    buildJson(object, type) {
      let result = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
      let objectType = object.type || null;
      if (_wizardSchema.default[type].types) {
        if (!objectType) {
          result.error = {
            type: "required",
            params: {
              type,
              property: "type"
            }
          };
          return result;
        }
      }
      for (let property of (0, _wizard.listProperties)(type, {
        objectType
      })) {
        let value = object.get(property);
        result = this.validateValue(property, value, object, type, result);
        if (result.error) {
          break;
        }
        if ((0, _wizardJson.mapped)(property, type)) {
          value = this.buildMappedJson(value);
        }
        if (value !== undefined && value !== null) {
          result[property] = value;
        }
      }
      if (!result.error) {
        for (let arrayObjectType of Object.keys(_wizardSchema.default[type].objectArrays)) {
          let arraySchema = _wizardSchema.default[type].objectArrays[arrayObjectType];
          let objectArray = object.get(arraySchema.property);
          if (arraySchema.required && !(0, _wizardJson.present)(objectArray)) {
            result.error = {
              type: "required",
              params: {
                type,
                property: arraySchema.property
              }
            };
            break;
          }
          result[arraySchema.property] = [];
          for (let item of objectArray) {
            let itemProps = this.buildJson(item, arrayObjectType);
            if (itemProps.error) {
              result.error = itemProps.error;
              break;
            } else {
              result[arraySchema.property].push(itemProps);
            }
          }
        }
      }
      return result;
    },
    validateValue(property, value, object, type, result) {
      if (_wizardSchema.default[type].required.indexOf(property) > -1 && !value) {
        result.error = {
          type: "required",
          params: {
            type,
            property
          }
        };
      }
      let dependent = _wizardSchema.default[type].dependent[property];
      if (dependent && value && !object[dependent]) {
        result.error = {
          type: "dependent",
          params: {
            property,
            dependent
          }
        };
      }
      if (property === "api_body") {
        try {
          value = JSON.parse(value);
        } catch (e) {
          result.error = {
            type: "invalid",
            params: {
              type,
              property
            }
          };
        }
      }
      return result;
    },
    buildMappedJson(value) {
      if (typeof value === "string" || Number.isInteger(value)) {
        return value;
      }
      if (!value || !value.length) {
        return false;
      }
      let inputs = value;
      let result = [];
      inputs.forEach(inpt => {
        let input = {
          type: inpt.type
        };
        if (inpt.connector) {
          input.connector = inpt.connector;
        }
        if ((0, _wizardJson.present)(inpt.output)) {
          input.output = inpt.output;
          input.output_type = (0, _wizard.snakeCase)(inpt.output_type);
          input.output_connector = inpt.output_connector;
        }
        if ((0, _wizardJson.present)(inpt.pairs)) {
          input.pairs = [];
          inpt.pairs.forEach(pr => {
            if ((0, _wizardJson.present)(pr.key) && (0, _wizardJson.present)(pr.value)) {
              let pairParams = {
                index: pr.index,
                key: pr.key,
                key_type: (0, _wizard.snakeCase)(pr.key_type),
                value: pr.value,
                value_type: (0, _wizard.snakeCase)(pr.value_type),
                connector: pr.connector
              };
              input.pairs.push(pairParams);
            }
          });
        }
        if (input.type === "assignment" && (0, _wizardJson.present)(input.output) || (0, _wizardJson.present)(input.pairs)) {
          result.push(input);
        }
      });
      if (!result.length) {
        result = false;
      }
      return result;
    },
    remove() {
      return (0, _ajax.ajax)(`/admin/wizards/wizard/${this.id}`, {
        type: "DELETE"
      }).then(() => this.destroy()).catch(_ajaxError.popupAjaxError);
    }
  }, (_applyDecoratedDescriptor(_obj, "allowGuests", [_dec], Object.getOwnPropertyDescriptor(_obj, "allowGuests"), _obj)), _obj)));
  CustomWizardAdmin.reopenClass({
    all() {
      return (0, _ajax.ajax)("/admin/wizards/wizard", {
        type: "GET"
      }).then(result => {
        return result.wizard_list;
      }).catch(_ajaxError.popupAjaxError);
    },
    submissions(wizardId) {
      let page = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;
      return (0, _ajax.ajax)(`/admin/wizards/submissions/${wizardId}`, {
        type: "GET",
        data: {
          page
        }
      }).catch(_ajaxError.popupAjaxError);
    },
    create() {
      let wizardJson = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      const wizard = this._super.apply(this);
      wizard.setProperties((0, _wizardJson.buildProperties)(wizardJson));
      return wizard;
    }
  });
  var _default = _exports.default = CustomWizardAdmin;
});