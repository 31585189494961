define("discourse/plugins/discourse-custom-wizard/discourse/components/wizard-mapper-selector", ["exports", "@ember/object/computed", "@ember/object", "discourse-common/utils/decorators", "@ember/application", "discourse/plugins/discourse-custom-wizard/discourse/lib/wizard-mapper", "discourse/plugins/discourse-custom-wizard/discourse/lib/wizard", "@ember/component", "@ember/runloop", "I18n", "@ember/service"], function (_exports, _computed, _object, _decorators, _application, _wizardMapper, _wizard, _component, _runloop, _I18n, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _obj;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer && (Object.defineProperty(i, e, a), a = null), a; }
  const customFieldActionMap = {
    topic: ["create_topic", "send_message"],
    post: ["create_topic", "send_message"],
    category: ["create_category"],
    group: ["create_group"],
    user: ["update_profile"]
  };
  const values = ["present", "true", "false"];
  var _default = _exports.default = _component.default.extend((_dec = (0, _decorators.default)("site.groups", "guestGroup", "subscription.subscriptionType"), _dec2 = (0, _decorators.default)("connector"), _dec3 = (0, _decorators.default)("activeType"), _dec4 = (0, _decorators.default)("activeType", "showController.wizardFields.[]", "showController.wizard.actions.[]", "showController.userFields.[]", "showController.currentField.id", "showController.currentAction.id", "showController.customFields"), _dec5 = (0, _decorators.default)("activeType"), _dec6 = (0, _decorators.default)("activeType", "inputType"), _dec7 = (0, _decorators.default)("activeType"), _dec8 = (0, _decorators.observes)("inputType"), (_obj = {
    classNameBindings: [":mapper-selector", "activeType"],
    subscription: (0, _service.inject)(),
    showText: (0, _object.computed)("activeType", function () {
      return this.showInput("text");
    }),
    showWizardField: (0, _object.computed)("activeType", function () {
      return this.showInput("wizardField");
    }),
    showWizardAction: (0, _object.computed)("activeType", function () {
      return this.showInput("wizardAction");
    }),
    showUserField: (0, _object.computed)("activeType", function () {
      return this.showInput("userField");
    }),
    showUserFieldOptions: (0, _object.computed)("activeType", function () {
      return this.showInput("userFieldOptions");
    }),
    showCategory: (0, _object.computed)("activeType", function () {
      return this.showInput("category");
    }),
    showTag: (0, _object.computed)("activeType", function () {
      return this.showInput("tag");
    }),
    showGroup: (0, _object.computed)("activeType", function () {
      return this.showInput("group");
    }),
    showUser: (0, _object.computed)("activeType", function () {
      return this.showInput("user");
    }),
    showList: (0, _object.computed)("activeType", function () {
      return this.showInput("list");
    }),
    showCustomField: (0, _object.computed)("activeType", function () {
      return this.showInput("customField");
    }),
    showValue: (0, _object.computed)("activeType", function () {
      return this.showInput("value");
    }),
    textEnabled: (0, _object.computed)("options.textSelection", "inputType", function () {
      return this.optionEnabled("textSelection");
    }),
    wizardFieldEnabled: (0, _object.computed)("options.wizardFieldSelection", "inputType", function () {
      return this.optionEnabled("wizardFieldSelection");
    }),
    wizardActionEnabled: (0, _object.computed)("options.wizardActionSelection", "inputType", function () {
      return this.optionEnabled("wizardActionSelection");
    }),
    customFieldEnabled: (0, _object.computed)("options.customFieldSelection", "inputType", function () {
      return this.optionEnabled("customFieldSelection");
    }),
    userFieldEnabled: (0, _object.computed)("options.userFieldSelection", "inputType", function () {
      return this.optionEnabled("userFieldSelection");
    }),
    userFieldOptionsEnabled: (0, _object.computed)("options.userFieldOptionsSelection", "inputType", function () {
      return this.optionEnabled("userFieldOptionsSelection");
    }),
    categoryEnabled: (0, _object.computed)("options.categorySelection", "inputType", function () {
      return this.optionEnabled("categorySelection");
    }),
    tagEnabled: (0, _object.computed)("options.tagSelection", "inputType", function () {
      return this.optionEnabled("tagSelection");
    }),
    groupEnabled: (0, _object.computed)("options.groupSelection", "inputType", function () {
      return this.optionEnabled("groupSelection");
    }),
    guestGroup: (0, _object.computed)("options.guestGroup", "inputType", function () {
      return this.optionEnabled("guestGroup");
    }),
    userEnabled: (0, _object.computed)("options.userSelection", "inputType", function () {
      return this.optionEnabled("userSelection");
    }),
    listEnabled: (0, _object.computed)("options.listSelection", "inputType", function () {
      return this.optionEnabled("listSelection");
    }),
    valueEnabled: (0, _object.computed)("connector", function () {
      return this.connector === "is";
    }),
    groups(groups, guestGroup, subscriptionType) {
      let result = groups;
      if (!guestGroup) {
        return result;
      }
      if (["standard", "business"].includes(subscriptionType)) {
        let guestIndex;
        result.forEach((r, index) => {
          if (r.id === 0) {
            r.name = _I18n.default.t("admin.wizard.selector.label.users");
            guestIndex = index;
          }
        });
        result.splice(guestIndex, 0, {
          id: -1,
          name: _I18n.default.t("admin.wizard.selector.label.guests")
        });
      }
      return result;
    },
    categories: (0, _computed.alias)("site.categories"),
    showComboBox: (0, _computed.or)("showWizardField", "showWizardAction", "showUserField", "showUserFieldOptions", "showCustomField", "showValue"),
    showMultiSelect: (0, _computed.or)("showCategory", "showGroup"),
    hasTypes: (0, _computed.gt)("selectorTypes.length", 1),
    showTypes: false,
    didInsertElement() {
      if (!this.activeType || this.activeType && !this[`${this.activeType}Enabled`]) {
        (0, _runloop.later)(() => this.resetActiveType());
      }
      $(document).on("click", (0, _runloop.bind)(this, this.documentClick));
    },
    willDestroyElement() {
      $(document).off("click", (0, _runloop.bind)(this, this.documentClick));
    },
    documentClick(e) {
      if (this._state === "destroying") {
        return;
      }
      let $target = $(e.target);
      if (!$target.parents(".type-selector").length && this.showTypes) {
        this.set("showTypes", false);
      }
    },
    selectorTypes() {
      return _wizardMapper.selectionTypes.filter(type => this[`${type}Enabled`]).map(type => ({
        type,
        label: this.typeLabel(type)
      }));
    },
    activeTypeLabel(activeType) {
      return this.typeLabel(activeType);
    },
    typeLabel(type) {
      return type ? _I18n.default.t(`admin.wizard.selector.label.${(0, _wizard.snakeCase)(type)}`) : null;
    },
    comboBoxAllowAny: (0, _computed.or)("showWizardField", "showWizardAction"),
    showController() {
      return (0, _application.getOwner)(this).lookup("controller:admin-wizards-wizard-show");
    },
    comboBoxContent(activeType, wizardFields, wizardActions, userFields, currentFieldId, currentActionId, customFields) {
      let content;
      let context;
      let contextType;
      if (this.options.context) {
        let contextAttrs = this.options.context.split(".");
        context = contextAttrs[0];
        contextType = contextAttrs[1];
      }
      if (activeType === "wizardField") {
        content = wizardFields;
        if (context === "field") {
          content = content.filter(field => field.id !== currentFieldId);
        }
      }
      if (activeType === "wizardAction") {
        content = wizardActions.map(a => ({
          id: a.id,
          label: `${(0, _wizard.generateName)(a.type)} (${a.id})`,
          type: a.type
        }));
        if (context === "action") {
          content = content.filter(a => a.id !== currentActionId);
        }
      }
      if (activeType === "userField") {
        content = _wizard.userProperties.map(f => ({
          id: f,
          name: (0, _wizard.generateName)(f)
        })).concat(userFields || []);
        if (context === "action" && this.inputType === "association" && this.selectorType === "key") {
          const excludedFields = ["username", "email", "trust_level"];
          content = content.filter(userField => excludedFields.indexOf(userField.id) === -1);
        }
      }
      if (activeType === "userFieldOptions") {
        content = userFields;
      }
      if (activeType === "customField") {
        content = customFields.filter(f => {
          return f.type !== "json" && customFieldActionMap[f.klass].includes(contextType);
        }).map(f => ({
          id: f.name,
          name: `${(0, _wizard.sentenceCase)(f.klass)} ${f.name} (${f.type})`
        }));
      }
      if (activeType === "value") {
        content = values.map(value => ({
          id: value,
          name: value
        }));
      }
      return content;
    },
    multiSelectContent(activeType) {
      return {
        category: this.categories,
        group: this.groups,
        list: ""
      }[activeType];
    },
    placeholderKey(activeType) {
      if (activeType === "text" && this.options[`${this.selectorType}Placeholder`]) {
        return this.options[`${this.selectorType}Placeholder`];
      } else {
        return `admin.wizard.selector.placeholder.${(0, _wizard.snakeCase)(activeType)}`;
      }
    },
    multiSelectOptions(activeType) {
      let result = {
        none: this.placeholderKey
      };
      if (activeType === "list") {
        result.allowAny = true;
      }
      return result;
    },
    optionEnabled(type) {
      const options = this.options;
      if (!options) {
        return false;
      }
      const option = options[type];
      if (option === true) {
        return true;
      }
      if (typeof option !== "string") {
        return false;
      }
      return option.split(",").filter(o => {
        return [this.selectorType, this.inputType].indexOf(o) !== -1;
      }).length;
    },
    showInput(type) {
      return this.activeType === type && this[`${type}Enabled`];
    },
    changeValue(value) {
      this.set("value", value);
      this.onUpdate("selector", this.activeType);
    },
    resetActiveType() {
      this.set("activeType", (0, _wizardMapper.defaultSelectionType)(this.selectorType, this.options, this.connector));
    },
    actions: {
      toggleType(type) {
        this.set("activeType", type);
        this.set("showTypes", false);
        this.set("value", null);
        this.onUpdate("selector");
      },
      toggleTypes() {
        this.toggleProperty("showTypes");
      },
      changeValue(value) {
        this.changeValue(value);
      },
      changeInputValue(event) {
        this.changeValue(event.target.value);
      },
      changeUserValue(value) {
        this.changeValue(value);
      }
    }
  }, (_applyDecoratedDescriptor(_obj, "groups", [_dec], Object.getOwnPropertyDescriptor(_obj, "groups"), _obj), _applyDecoratedDescriptor(_obj, "selectorTypes", [_dec2], Object.getOwnPropertyDescriptor(_obj, "selectorTypes"), _obj), _applyDecoratedDescriptor(_obj, "activeTypeLabel", [_dec3], Object.getOwnPropertyDescriptor(_obj, "activeTypeLabel"), _obj), _applyDecoratedDescriptor(_obj, "showController", [_decorators.default], Object.getOwnPropertyDescriptor(_obj, "showController"), _obj), _applyDecoratedDescriptor(_obj, "comboBoxContent", [_dec4], Object.getOwnPropertyDescriptor(_obj, "comboBoxContent"), _obj), _applyDecoratedDescriptor(_obj, "multiSelectContent", [_dec5], Object.getOwnPropertyDescriptor(_obj, "multiSelectContent"), _obj), _applyDecoratedDescriptor(_obj, "placeholderKey", [_dec6], Object.getOwnPropertyDescriptor(_obj, "placeholderKey"), _obj), _applyDecoratedDescriptor(_obj, "multiSelectOptions", [_dec7], Object.getOwnPropertyDescriptor(_obj, "multiSelectOptions"), _obj), _applyDecoratedDescriptor(_obj, "resetActiveType", [_dec8], Object.getOwnPropertyDescriptor(_obj, "resetActiveType"), _obj)), _obj)));
});