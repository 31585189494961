define("discourse/plugins/discourse-custom-wizard/discourse/components/custom-wizard-text-field", ["exports", "discourse-common/utils/decorators", "discourse/lib/text-direction", "I18n", "discourse/components/text-field"], function (_exports, _decorators, _textDirection, _I18n, _textField) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _obj;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer && (Object.defineProperty(i, e, a), a = null), a; }
  var _default = _exports.default = _textField.default.extend((_dec = (0, _decorators.default)("placeholderKey"), (_obj = {
    attributeBindings: ["autocorrect", "autocapitalize", "autofocus", "maxLength", "dir"],
    dir() {
      if (this.siteSettings.support_mixed_text_direction) {
        let val = this.value;
        if (val) {
          return (0, _textDirection.isRTL)(val) ? "rtl" : "ltr";
        } else {
          return (0, _textDirection.siteDir)();
        }
      }
    },
    keyUp() {
      if (this.siteSettings.support_mixed_text_direction) {
        let val = this.value;
        if ((0, _textDirection.isRTL)(val)) {
          this.set("dir", "rtl");
        } else if ((0, _textDirection.isLTR)(val)) {
          this.set("dir", "ltr");
        } else {
          this.set("dir", (0, _textDirection.siteDir)());
        }
      }
    },
    placeholder(placeholderKey) {
      return placeholderKey ? _I18n.default.t(placeholderKey) : "";
    }
  }, (_applyDecoratedDescriptor(_obj, "dir", [_decorators.default], Object.getOwnPropertyDescriptor(_obj, "dir"), _obj), _applyDecoratedDescriptor(_obj, "placeholder", [_dec], Object.getOwnPropertyDescriptor(_obj, "placeholder"), _obj)), _obj)));
});