define("discourse/plugins/discourse-custom-wizard/discourse/components/wizard-custom-field", ["exports", "discourse-common/utils/decorators", "@ember/object/computed", "@ember/object", "discourse/plugins/discourse-custom-wizard/discourse/lib/wizard", "discourse/plugins/discourse-custom-wizard/discourse/mixins/undo-changes", "@ember/component", "discourse/plugins/discourse-custom-wizard/discourse/lib/wizard-schema"], function (_exports, _decorators, _computed, _object, _wizard, _undoChanges, _component, _wizardSchema) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _obj;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer && (Object.defineProperty(i, e, a), a = null), a; }
  var _default = _exports.default = _component.default.extend(_undoChanges.default, (_dec = (0, _decorators.default)("field.type"), _dec2 = (0, _decorators.default)("field.type"), _dec3 = (0, _decorators.default)("field.type"), _dec4 = (0, _decorators.default)("field.type"), _dec5 = (0, _decorators.default)("field.type"), _dec6 = (0, _decorators.default)("step.index"), _dec7 = (0, _decorators.default)("step.index"), (_obj = {
    componentType: "field",
    classNameBindings: [":wizard-custom-field", "visible"],
    visible: (0, _object.computed)("currentFieldId", function () {
      return this.field.id === this.currentFieldId;
    }),
    isDropdown: (0, _computed.equal)("field.type", "dropdown"),
    isUpload: (0, _computed.equal)("field.type", "upload"),
    isCategory: (0, _computed.equal)("field.type", "category"),
    isGroup: (0, _computed.equal)("field.type", "group"),
    isTag: (0, _computed.equal)("field.type", "tag"),
    isText: (0, _computed.equal)("field.type", "text"),
    isTextarea: (0, _computed.equal)("field.type", "textarea"),
    isUrl: (0, _computed.equal)("field.type", "url"),
    isComposer: (0, _computed.equal)("field.type", "composer"),
    showPrefill: (0, _computed.or)("isText", "isCategory", "isTag", "isGroup", "isDropdown"),
    showContent: (0, _computed.or)("isCategory", "isTag", "isGroup", "isDropdown"),
    showLimit: (0, _computed.or)("isCategory", "isTag"),
    isTextType: (0, _computed.or)("isText", "isTextarea", "isComposer"),
    isComposerPreview: (0, _computed.equal)("field.type", "composer_preview"),
    categoryPropertyTypes: (0, _wizard.selectKitContent)(["id", "slug"]),
    messageUrl: "https://pavilion.tech/products/discourse-custom-wizard-plugin/documentation/field-settings",
    validations(type) {
      const applicableToField = [];
      for (let validation in _wizardSchema.default.field.validations) {
        if (_wizardSchema.default.field.validations[validation]["types"].includes(type)) {
          applicableToField.push(validation);
        }
      }
      return applicableToField;
    },
    isDateTime(type) {
      return ["date_time", "date", "time"].indexOf(type) > -1;
    },
    messageKey(type) {
      let key = "type";
      if (type) {
        key = "edit";
      }
      return key;
    },
    setupTypeOutput(fieldType, options) {
      const selectionType = {
        category: "category",
        tag: "tag",
        group: "group"
      }[fieldType];
      if (selectionType) {
        options[`${selectionType}Selection`] = "output";
        options.outputDefaultSelection = selectionType;
      }
      return options;
    },
    contentOptions(fieldType) {
      let options = {
        wizardFieldSelection: true,
        textSelection: "key,value",
        userFieldSelection: "key,value",
        context: "field"
      };
      options = this.setupTypeOutput(fieldType, options);
      if (this.isDropdown) {
        options.wizardFieldSelection = "key,value";
        options.userFieldOptionsSelection = "output";
        options.textSelection = "key,value";
        options.inputTypes = "association,conditional,assignment";
        options.pairConnector = "association";
        options.keyPlaceholder = "admin.wizard.key";
        options.valuePlaceholder = "admin.wizard.value";
      }
      return options;
    },
    prefillOptions(fieldType) {
      let options = {
        wizardFieldSelection: true,
        textSelection: true,
        userFieldSelection: "key,value",
        context: "field"
      };
      return this.setupTypeOutput(fieldType, options);
    },
    fieldConditionOptions(stepIndex) {
      const options = {
        inputTypes: "validation",
        context: "field",
        textSelection: "value",
        userFieldSelection: true,
        groupSelection: true
      };
      if (stepIndex > 0) {
        options.wizardFieldSelection = true;
        options.wizardActionSelection = true;
      }
      return options;
    },
    fieldIndexOptions(stepIndex) {
      const options = {
        context: "field",
        userFieldSelection: true,
        groupSelection: true
      };
      if (stepIndex > 0) {
        options.wizardFieldSelection = true;
        options.wizardActionSelection = true;
      }
      return options;
    },
    actions: {
      imageUploadDone(upload) {
        this.setProperties({
          "field.image": upload.url,
          "field.image_upload_id": upload.id
        });
      },
      imageUploadDeleted() {
        this.setProperties({
          "field.image": null,
          "field.image_upload_id": null
        });
      }
    }
  }, (_applyDecoratedDescriptor(_obj, "validations", [_dec], Object.getOwnPropertyDescriptor(_obj, "validations"), _obj), _applyDecoratedDescriptor(_obj, "isDateTime", [_dec2], Object.getOwnPropertyDescriptor(_obj, "isDateTime"), _obj), _applyDecoratedDescriptor(_obj, "messageKey", [_dec3], Object.getOwnPropertyDescriptor(_obj, "messageKey"), _obj), _applyDecoratedDescriptor(_obj, "contentOptions", [_dec4], Object.getOwnPropertyDescriptor(_obj, "contentOptions"), _obj), _applyDecoratedDescriptor(_obj, "prefillOptions", [_dec5], Object.getOwnPropertyDescriptor(_obj, "prefillOptions"), _obj), _applyDecoratedDescriptor(_obj, "fieldConditionOptions", [_dec6], Object.getOwnPropertyDescriptor(_obj, "fieldConditionOptions"), _obj), _applyDecoratedDescriptor(_obj, "fieldIndexOptions", [_dec7], Object.getOwnPropertyDescriptor(_obj, "fieldIndexOptions"), _obj)), _obj)));
});