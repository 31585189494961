define("discourse/plugins/discourse-custom-wizard/discourse/components/custom-wizard-field-composer", ["exports", "discourse-common/utils/decorators", "@ember/object", "@ember/component"], function (_exports, _decorators, _object, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _obj;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer && (Object.defineProperty(i, e, a), a = null), a; }
  var _default = _exports.default = _component.default.extend((_dec = (0, _decorators.observes)("composer.reply"), _dec2 = (0, _decorators.default)("showPreview"), (_obj = {
    showPreview: false,
    classNameBindings: [":wizard-field-composer", "showPreview:show-preview:hide-preview"],
    didInsertElement() {
      this.set("composer", _object.default.create({
        loading: false,
        reply: this.get("field.value") || ""
      }));
    },
    setField() {
      this.set("field.value", this.get("composer.reply"));
    },
    togglePreviewLabel(showPreview) {
      return showPreview ? "wizard_composer.hide_preview" : "wizard_composer.show_preview";
    },
    actions: {
      togglePreview() {
        this.toggleProperty("showPreview");
      },
      groupsMentioned() {},
      afterRefresh() {},
      cannotSeeMention() {},
      importQuote() {},
      showUploadSelector() {}
    }
  }, (_applyDecoratedDescriptor(_obj, "setField", [_dec], Object.getOwnPropertyDescriptor(_obj, "setField"), _obj), _applyDecoratedDescriptor(_obj, "togglePreviewLabel", [_dec2], Object.getOwnPropertyDescriptor(_obj, "togglePreviewLabel"), _obj)), _obj)));
});