define("discourse/plugins/discourse-custom-wizard/discourse/components/custom-wizard-field-time", ["exports", "@ember/component", "discourse-common/utils/decorators"], function (_exports, _component, _decorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _obj;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer && (Object.defineProperty(i, e, a), a = null), a; }
  var _default = _exports.default = _component.default.extend((_dec = (0, _decorators.observes)("time"), (_obj = {
    classNameBindings: ["fieldClass"],
    setValue() {
      this.set("field.value", this.time.format(this.field.format));
    },
    actions: {
      onChange(value) {
        this.set("time", moment({
          hours: value.hours,
          minutes: value.minutes
        }));
      }
    }
  }, (_applyDecoratedDescriptor(_obj, "setValue", [_dec], Object.getOwnPropertyDescriptor(_obj, "setValue"), _obj)), _obj)));
});