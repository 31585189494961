define("discourse/plugins/discourse-custom-wizard/discourse/components/custom-wizard-field-category", ["exports", "discourse-common/utils/decorators", "discourse/models/category", "@ember/component"], function (_exports, _decorators, _category, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _obj;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer && (Object.defineProperty(i, e, a), a = null), a; }
  var _default = _exports.default = _component.default.extend((_dec = (0, _decorators.observes)("categories"), (_obj = {
    categories: [],
    didInsertElement() {
      const property = this.field.property || "id";
      const value = this.field.value;
      if (value) {
        this.set("categories", [...value].reduce((result, v) => {
          let val = property === "id" ? _category.default.findById(v) : _category.default.findBySlug(v);
          if (val) {
            result.push(val);
          }
          return result;
        }, []));
      }
    },
    setValue() {
      const categories = (this.categories || []).filter(c => !!c);
      const property = this.field.property || "id";
      if (categories.length) {
        this.set("field.value", categories.reduce((result, c) => {
          if (c && c[property]) {
            result.push(c[property]);
          }
          return result;
        }, []));
      }
    }
  }, (_applyDecoratedDescriptor(_obj, "setValue", [_dec], Object.getOwnPropertyDescriptor(_obj, "setValue"), _obj)), _obj)));
});