define("discourse/plugins/discourse-custom-wizard/discourse/components/modal/next-session-scheduled", ["exports", "@ember/component", "@glimmer/component", "@glimmer/tracking", "@ember/object", "I18n", "@ember/template-factory"], function (_exports, _component, _component2, _tracking, _object, _I18n, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer && (Object.defineProperty(i, e, a), a = null), a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <DModal
    @closeModal={{@closeModal}}
    class="next-session-time-modal"
    @title={{this.title}}
  >
    <DateTimeInput
      @date={{this.bufferedDateTime}}
      @onChange={{action "dateTimeChanged"}}
      @showTime="true"
      @clearable="true"
    />
    <div class="modal-footer">
      <DButton
        @action={{action "submit"}}
        class="btn-primary"
        @label="admin.wizard.after_time_modal.done"
        @disabled={{this.submitDisabled}}
      />
    </div>
  </DModal>
  */
  {
    "id": "g+Hau1pv",
    "block": "[[[8,[39,0],[[24,0,\"next-session-time-modal\"]],[[\"@closeModal\",\"@title\"],[[30,1],[30,0,[\"title\"]]]],[[\"default\"],[[[[1,\"\\n  \"],[8,[39,1],null,[[\"@date\",\"@onChange\",\"@showTime\",\"@clearable\"],[[30,0,[\"bufferedDateTime\"]],[28,[37,2],[[30,0],\"dateTimeChanged\"],null],\"true\",\"true\"]],null],[1,\"\\n  \"],[10,0],[14,0,\"modal-footer\"],[12],[1,\"\\n    \"],[8,[39,3],[[24,0,\"btn-primary\"]],[[\"@action\",\"@label\",\"@disabled\"],[[28,[37,2],[[30,0],\"submit\"],null],\"admin.wizard.after_time_modal.done\",[30,0,[\"submitDisabled\"]]]],null],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]]]]]],[\"@closeModal\"],false,[\"d-modal\",\"date-time-input\",\"action\",\"d-button\"]]",
    "moduleName": "discourse/plugins/discourse-custom-wizard/discourse/components/modal/next-session-scheduled.hbs",
    "isStrictMode": false
  });
  let NextSessionScheduledComponent = _exports.default = (_class = class NextSessionScheduledComponent extends _component2.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "bufferedDateTime", _descriptor, this);
      _defineProperty(this, "title", _I18n.default.t("admin.wizard.after_time_modal.title"));
      this.bufferedDateTime = this.args.model.dateTime ? moment(this.args.model.dateTime) : moment(Date.now());
    }
    get submitDisabled() {
      return moment().isAfter(this.bufferedDateTime);
    }
    submit() {
      const dateTime = this.bufferedDateTime;
      this.args.model.update(moment(dateTime).utc().toISOString());
      this.args.closeModal();
    }
    dateTimeChanged(dateTime) {
      this.bufferedDateTime = dateTime;
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "bufferedDateTime", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "submit", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "submit"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "dateTimeChanged", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "dateTimeChanged"), _class.prototype)), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, NextSessionScheduledComponent);
});