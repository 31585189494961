define("discourse/plugins/discourse-custom-wizard/discourse/services/subscription", ["exports", "@ember/service", "@glimmer/tracking", "discourse/lib/ajax", "discourse/lib/ajax-error"], function (_exports, _service, _tracking, _ajax, _ajaxError) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer && (Object.defineProperty(i, e, a), a = null), a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const PRODUCT_PAGE = "https://custom-wizard.pavilion.tech/pricing";
  const SUPPORT_MESSAGE = "https://coop.pavilion.tech/new-message?username=support&title=Custom%20Wizard%20Support";
  const MANAGER_CATEGORY = "https://coop.pavilion.tech/c/support/discourse-custom-wizard";
  let SubscriptionService = _exports.default = (_class = class SubscriptionService extends _service.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "subscribed", _descriptor, this);
      _initializerDefineProperty(this, "subscriptionType", _descriptor2, this);
      _initializerDefineProperty(this, "businessSubscription", _descriptor3, this);
      _initializerDefineProperty(this, "communitySubscription", _descriptor4, this);
      _initializerDefineProperty(this, "standardSubscription", _descriptor5, this);
      _initializerDefineProperty(this, "subscriptionAttributes", _descriptor6, this);
    }
    async init() {
      super.init(...arguments);
      await this.retrieveSubscriptionStatus();
    }
    async retrieveSubscriptionStatus() {
      let result = await (0, _ajax.ajax)("/admin/wizards/subscription").catch(_ajaxError.popupAjaxError);
      this.subscribed = result.subscribed;
      this.subscriptionType = result.subscription_type;
      this.subscriptionAttributes = result.subscription_attributes;
      this.businessSubscription = this.subscriptionType === "business";
      this.communitySubscription = this.subscriptionType === "community";
      this.standardSubscription = this.subscriptionType === "standard";
    }
    async updateSubscriptionStatus() {
      let result = await (0, _ajax.ajax)("/admin/wizards/subscription?update_from_remote=true").catch(_ajaxError.popupAjaxError);
      this.subscribed = result.subscribed;
      this.subscriptionType = result.subscription_type;
      this.subscriptionAttributes = result.subscription_attributes;
      this.businessSubscription = this.subscriptionType === "business";
      this.communitySubscription = this.subscriptionType === "community";
      this.standardSubscription = this.subscriptionType === "standard";
    }
    get subscriptionCtaLink() {
      switch (this.subscriptionType) {
        case "none":
          return PRODUCT_PAGE;
        case "standard":
          return SUPPORT_MESSAGE;
        case "business":
          return SUPPORT_MESSAGE;
        case "community":
          return MANAGER_CATEGORY;
        default:
          return PRODUCT_PAGE;
      }
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "subscribed", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "subscriptionType", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return "";
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "businessSubscription", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "communitySubscription", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "standardSubscription", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "subscriptionAttributes", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return {};
    }
  })), _class);
});