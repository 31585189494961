define("discourse/plugins/discourse-custom-wizard/discourse/models/custom-wizard-api", ["exports", "discourse/lib/ajax", "discourse-common/utils/decorators", "@ember/object", "@ember/array"], function (_exports, _ajax, _decorators, _object, _array) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _obj;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer && (Object.defineProperty(i, e, a), a = null), a; }
  const CustomWizardApi = _object.default.extend((_dec = (0, _decorators.default)("name"), (_obj = {
    redirectUri(name) {
      let nameParam = name.toString().dasherize();
      const baseUrl = location.protocol + "//" + location.hostname + (location.port ? ":" + location.port : "");
      return baseUrl + `/admin/wizards/apis/${nameParam}/redirect`;
    }
  }, (_applyDecoratedDescriptor(_obj, "redirectUri", [_dec], Object.getOwnPropertyDescriptor(_obj, "redirectUri"), _obj)), _obj)));
  CustomWizardApi.reopenClass({
    create() {
      let params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      const api = this._super.apply(this);
      const authorization = params.authorization || {};
      const endpoints = params.endpoints;
      api.setProperties({
        name: params.name,
        title: params.title,
        originalTitle: params.title,
        authType: authorization.auth_type,
        authUrl: authorization.auth_url,
        tokenUrl: authorization.token_url,
        clientId: authorization.client_id,
        clientSecret: authorization.client_secret,
        username: authorization.username,
        password: authorization.password,
        authParams: (0, _array.A)(authorization.auth_params),
        authorized: authorization.authorized,
        accessToken: authorization.access_token,
        refreshToken: authorization.refresh_token,
        code: authorization.code,
        tokenExpiresAt: authorization.token_expires_at,
        tokenRefreshAt: authorization.token_refresh_at,
        endpoints: (0, _array.A)(endpoints),
        isNew: params.isNew,
        log: params.log
      });
      return api;
    },
    find(name) {
      return (0, _ajax.ajax)(`/admin/wizards/api/${name}`, {
        type: "GET"
      }).then(result => {
        return CustomWizardApi.create(result);
      });
    },
    list() {
      return (0, _ajax.ajax)("/admin/wizards/api", {
        type: "GET"
      }).then(result => {
        return result;
      });
    }
  });
  var _default = _exports.default = CustomWizardApi;
});