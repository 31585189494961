define("discourse/plugins/discourse-custom-wizard/discourse/components/wizard-message", ["exports", "discourse-common/utils/decorators", "@ember/object/computed", "@ember/component", "I18n"], function (_exports, _decorators, _computed, _component, _I18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _obj;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer && (Object.defineProperty(i, e, a), a = null), a; }
  const icons = {
    error: "times-circle",
    success: "check-circle",
    warn: "exclamation-circle",
    info: "info-circle"
  };
  var _default = _exports.default = _component.default.extend((_dec = (0, _decorators.default)("type"), _dec2 = (0, _decorators.default)("key", "component", "opts"), _dec3 = (0, _decorators.default)("component"), (_obj = {
    classNameBindings: [":wizard-message", "type", "loading"],
    showDocumentation: (0, _computed.not)("loading"),
    showIcon: (0, _computed.not)("loading"),
    hasItems: (0, _computed.notEmpty)("items"),
    icon(type) {
      return icons[type] || "info-circle";
    },
    message(key, component, opts) {
      return _I18n.default.t(`admin.wizard.message.${component}.${key}`, opts || {});
    },
    documentation(component) {
      return _I18n.default.t(`admin.wizard.message.${component}.documentation`);
    }
  }, (_applyDecoratedDescriptor(_obj, "icon", [_dec], Object.getOwnPropertyDescriptor(_obj, "icon"), _obj), _applyDecoratedDescriptor(_obj, "message", [_dec2], Object.getOwnPropertyDescriptor(_obj, "message"), _obj), _applyDecoratedDescriptor(_obj, "documentation", [_dec3], Object.getOwnPropertyDescriptor(_obj, "documentation"), _obj)), _obj)));
});