define("discourse/plugins/discourse-custom-wizard/discourse/controllers/admin-wizards-logs", ["exports", "@ember/controller", "discourse-common/utils/decorators"], function (_exports, _controller, _decorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _obj;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer && (Object.defineProperty(i, e, a), a = null), a; }
  var _default = _exports.default = _controller.default.extend((_dec = (0, _decorators.default)("wizardId"), _dec2 = (0, _decorators.default)("wizardName"), _dec3 = (0, _decorators.default)("wizardId"), (_obj = {
    documentationUrl: "https://pavilion.tech/products/discourse-custom-wizard-plugin/documentation/",
    wizardName(wizardId) {
      let currentWizard = this.wizardList.find(wizard => wizard.id === wizardId);
      if (currentWizard) {
        return currentWizard.name;
      }
    },
    messageOpts(wizardName) {
      return {
        wizardName
      };
    },
    messageKey(wizardId) {
      let key = "select";
      if (wizardId) {
        key = "viewing";
      }
      return key;
    }
  }, (_applyDecoratedDescriptor(_obj, "wizardName", [_dec], Object.getOwnPropertyDescriptor(_obj, "wizardName"), _obj), _applyDecoratedDescriptor(_obj, "messageOpts", [_dec2], Object.getOwnPropertyDescriptor(_obj, "messageOpts"), _obj), _applyDecoratedDescriptor(_obj, "messageKey", [_dec3], Object.getOwnPropertyDescriptor(_obj, "messageKey"), _obj)), _obj)));
});