define("discourse/plugins/discourse-custom-wizard/discourse/components/wizard-custom-step", ["exports", "@ember/component", "discourse-common/utils/decorators"], function (_exports, _component, _decorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _obj;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer && (Object.defineProperty(i, e, a), a = null), a; }
  var _default = _exports.default = _component.default.extend((_dec = (0, _decorators.default)("step.index"), (_obj = {
    classNames: "wizard-custom-step",
    stepConditionOptions(stepIndex) {
      const options = {
        inputTypes: "validation",
        context: "step",
        textSelection: "value",
        userFieldSelection: true,
        groupSelection: true
      };
      if (stepIndex > 0) {
        options["wizardFieldSelection"] = true;
        options["wizardActionSelection"] = true;
      }
      return options;
    },
    actions: {
      bannerUploadDone(upload) {
        this.setProperties({
          "step.banner": upload.url,
          "step.banner_upload_id": upload.id
        });
      },
      bannerUploadDeleted() {
        this.setProperties({
          "step.banner": null,
          "step.banner_upload_id": null
        });
      }
    }
  }, (_applyDecoratedDescriptor(_obj, "stepConditionOptions", [_dec], Object.getOwnPropertyDescriptor(_obj, "stepConditionOptions"), _obj)), _obj)));
});