define("discourse/plugins/discourse-custom-wizard/discourse/components/wizard-subscription-selector", ["exports", "select-kit/components/single-select", "@ember/service", "discourse/plugins/discourse-custom-wizard/discourse/lib/wizard-schema", "discourse-common/utils/decorators", "I18n"], function (_exports, _singleSelect, _service, _wizardSchema, _decorators, _I18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _obj;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer && (Object.defineProperty(i, e, a), a = null), a; }
  const nameKey = function (feature, attribute, value) {
    if (feature === "action") {
      return `admin.wizard.action.${value}.label`;
    } else {
      return `admin.wizard.${feature}.${attribute}.${value}`;
    }
  };
  var _default = _exports.default = _singleSelect.default.extend((_dec = (0, _decorators.default)("feature", "attribute", "wizard.allowGuests"), (_obj = {
    classNames: ["combo-box", "wizard-subscription-selector"],
    subscription: (0, _service.inject)(),
    selectKitOptions: {
      autoFilterable: false,
      filterable: false,
      showFullTitle: true,
      headerComponent: "wizard-subscription-selector/wizard-subscription-selector-header",
      caretUpIcon: "caret-up",
      caretDownIcon: "caret-down"
    },
    allowedSubscriptionTypes(feature, attribute, value) {
      let attributes = this.subscription.subscriptionAttributes[feature];
      if (!attributes || !attributes[attribute]) {
        return ["none"];
      }
      let allowedTypes = [];
      Object.keys(attributes[attribute]).forEach(subscriptionType => {
        let values = attributes[attribute][subscriptionType];
        if (values[0] === "*" || values.includes(value)) {
          allowedTypes.push(subscriptionType);
        }
      });
      return allowedTypes;
    },
    content(feature, attribute) {
      return (0, _wizardSchema.filterValues)(this.wizard, feature, attribute).map(value => {
        let allowedSubscriptionTypes = this.allowedSubscriptionTypes(feature, attribute, value);
        let subscriptionRequired = allowedSubscriptionTypes.length && !allowedSubscriptionTypes.includes("none");
        let attrs = {
          id: value,
          name: _I18n.default.t(nameKey(feature, attribute, value)),
          subscriptionRequired
        };
        if (subscriptionRequired) {
          let subscribed = allowedSubscriptionTypes.includes(this.subscription.subscriptionType);
          let selectorKey = subscribed ? "subscribed" : "not_subscribed";
          let selectorLabel = `admin.wizard.subscription.${selectorKey}.selector`;
          attrs.disabled = !subscribed;
          attrs.selectorLabel = selectorLabel;
        }
        return attrs;
      }).sort(function (a, b) {
        if (a.subscriptionType && !b.subscriptionType) {
          return 1;
        }
        if (!a.subscriptionType && b.subscriptionType) {
          return -1;
        }
        if (a.subscriptionType === b.subscriptionType) {
          return a.subscriptionType ? a.subscriptionType.localeCompare(b.subscriptionType) : 0;
        } else {
          return a.subscriptionType === "standard" ? -1 : 0;
        }
      });
    },
    modifyComponentForRow() {
      return "wizard-subscription-selector/wizard-subscription-selector-row";
    }
  }, (_applyDecoratedDescriptor(_obj, "content", [_dec], Object.getOwnPropertyDescriptor(_obj, "content"), _obj)), _obj)));
});