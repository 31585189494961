define("discourse/plugins/discourse-custom-wizard/discourse/components/wizard-realtime-validations", ["exports", "@ember/component", "@ember/object", "discourse-common/lib/object", "discourse/models/category", "discourse-common/utils/decorators", "I18n"], function (_exports, _component, _object, _object2, _category, _decorators, _I18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _obj;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer && (Object.defineProperty(i, e, a), a = null), a; }
  var _default = _exports.default = _component.default.extend((_obj = {
    classNames: ["realtime-validations", "setting", "full", "subscription"],
    timeUnits() {
      return ["days", "weeks", "months", "years"].map(unit => {
        return {
          id: unit,
          name: _I18n.default.t(`admin.wizard.field.validations.time_units.${unit}`)
        };
      });
    },
    init() {
      this._super(...arguments);
      if (!this.validations) {
        return;
      }
      if (!this.field.validations) {
        const validations = {};
        this.validations.forEach(validation => {
          validations[validation] = {};
        });
        this.set("field.validations", _object.default.create(validations));
      }
      const validationBuffer = (0, _object2.cloneJSON)(this.get("field.validations"));
      let bufferCategories = validationBuffer.similar_topics?.categories || [];
      if (bufferCategories) {
        validationBuffer.similar_topics.categories = _category.default.findByIds(bufferCategories);
      } else {
        validationBuffer.similar_topics.categories = [];
      }
      this.set("validationBuffer", validationBuffer);
    },
    actions: {
      updateValidationCategories(type, validation, categories) {
        this.set(`validationBuffer.${type}.categories`, categories);
        this.set(`field.validations.${type}.categories`, categories.map(category => category.id));
      }
    }
  }, (_applyDecoratedDescriptor(_obj, "timeUnits", [_decorators.default], Object.getOwnPropertyDescriptor(_obj, "timeUnits"), _obj)), _obj));
});