define("discourse/plugins/discourse-custom-wizard/discourse/components/custom-wizard-similar-topics", ["exports", "@ember/component", "@ember/runloop", "discourse-common/utils/decorators"], function (_exports, _component, _runloop, _decorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _obj;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer && (Object.defineProperty(i, e, a), a = null), a; }
  var _default = _exports.default = _component.default.extend((_dec = (0, _decorators.observes)("topics"), (_obj = {
    classNames: ["wizard-similar-topics"],
    showTopics: true,
    didInsertElement() {
      $(document).on("click", (0, _runloop.bind)(this, this.documentClick));
    },
    willDestroyElement() {
      $(document).off("click", (0, _runloop.bind)(this, this.documentClick));
    },
    documentClick(e) {
      if (this._state === "destroying") {
        return;
      }
      let $target = $(e.target);
      if (!$target.hasClass("show-topics")) {
        this.set("showTopics", false);
      }
    },
    toggleShowWhenTopicsChange() {
      this.set("showTopics", true);
    },
    actions: {
      toggleShowTopics() {
        this.set("showTopics", true);
      }
    }
  }, (_applyDecoratedDescriptor(_obj, "toggleShowWhenTopicsChange", [_dec], Object.getOwnPropertyDescriptor(_obj, "toggleShowWhenTopicsChange"), _obj)), _obj)));
});